.event-card {
  background-color: #ffa07a; /* Cor de fundo alaranjada semelhante à da imagem */
  color: #ffffff; /* Texto branco */
  font-family: "Open Sans", sans-serif; /* Tipo de letra genérico, substitua pelo específico se necessário */
  padding: 20px; /* Espaçamento interno */
  border-radius: 8px; /* Bordas arredondadas */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Sombra suave */
  max-width: 300px; /* Largura máxima do card */
  margin: 20px; /* Margem externa */
  text-align: center; /* Texto centralizado */
}
.event-info h1 {
  font-size: 2.5em; /* Tamanho da fonte do título */
  margin-bottom: 0.5em; /* Espaço abaixo do título */
}

.event-info p {
  font-size: 1.2em; /* Tamanho da fonte dos parágrafos */
  margin-bottom: 0.5em; /* Espaço abaixo dos parágrafos */
}

.event-info .speakers {
  font-style: italic; /* Estilo de fonte para os nomes dos palestrantes */
}
.containerCibea {
  display: flex;
  width: 100%;
}
.fullscreen-background {
  background-image: url("../images/fundo.webp");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.8;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.inscricao {
  float: left;
  position: relative;
  z-index: 1;
  color: black;
  font-size: x-large;
  left: 50%;
  margin-left: -100px;
}
.home {
  background-image: url("../images/fundoPage.webp");
  background-repeat: no-repeat;
  background-size: contain;
  opacity: 0.8;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  height: 100vh;
}

.home h1 {
  padding-top: 3rem;
}

.error {
  color: red !important;
  font-weight: bolder !important;
}

.formulario {
  display: flex;
  flex-flow: row-reverse;
  align-content: space-around;
  align-items: stretch;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}
.formulario form {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
}

.bgWhite {
  display: flex;
  justify-content: center;
  padding: 20px;
  background-color: #ffffff;
}

@media (max-width: 768px) {
  .fullscreen-background {
    background-image: url("../images/fundoMobile.webp");
    background-position: top center;
  }
  .bgWhite {
    display: flex;
    justify-content: center;
    padding: 20px;
    background-color: #ffffff;
    flex-direction: column;
  }
  .responsive {
    width: 100%;
  }
}

@media (max-width: 480px) {
  .fullscreen-background {
    background-image: url("../images/fundoMobile.webp");
    background-position: top center;
    width: 100vw;
    height: 100vh;
  }
}
